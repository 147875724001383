import React from 'react'
import { EventCaption, EventHeader } from '../../pages/EventView/Event.elements';
import { Background, CloseModalButton, ModalContent, ModalHeader, RsvpModalWrapper } from './Modal.elements';
type modelData = {
    showModal : boolean;
    setShowModal: () => void;
    modalBody?: string | JSX.Element;
    cardName:any,
    cardType: any,
    cardPrice: any
}
const RsvpModal:React.FC<modelData> = ({showModal,setShowModal,modalBody,cardName,cardType,cardPrice})=>{
        return(
           <>
           {showModal?(
               <Background>
             <RsvpModalWrapper>
             <ModalContent>
                 <ModalHeader>
                     <EventHeader>{cardName}</EventHeader>
                     <EventCaption>{cardType}</EventCaption>
                    <EventCaption>
                    {cardPrice}
                    </EventCaption>

                    <EventCaption>Jaza taarifa / Ahadi yako</EventCaption>
                 </ModalHeader>
                 {modalBody}
             </ModalContent>
                     <CloseModalButton
                             aria-label='Close modal'
                             onClick={setShowModal}
                         />
         </RsvpModalWrapper>
         </Background>
           ):null}
           </>
        );
}


export default RsvpModal;