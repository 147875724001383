export class CardTemplate {
    template_id: number;
    name: string;
    payment_status: boolean;
    price: string;
    event_type: string;
    card_category: string;
    preview_path: string;
    created_at: string;

    constructor(template:any){
        this.template_id = template.id;
        this.name = template.name;
        this.payment_status = template.payment_status;
        this.price = template.price;
        this.event_type = template.event_type;
        this.card_category = template.card_category;
        this.preview_path = template.preview_path;
        this.created_at = template.created_at;
    }
}

