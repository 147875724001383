import React from 'react'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { FooterContent, FooterBottom, FooterContainer, FooterWrapper, SocialHolder, SocialIconLink, WebsiteRights } from './Footer.elemets'

function Footer() {
    return (
        <FooterWrapper>

        <FooterContainer>
          
          
          <FooterBottom>
         
                  <WebsiteRights>
                  © 2022 HarusiOnline by <a href="http://www.sj.co.tz">SJ Softwares</a>
                  </WebsiteRights>
                  <WebsiteRights>
                  <a href="/terms">Terms & Conditions</a>
                  </WebsiteRights>
                  <SocialHolder>
                  <SocialIconLink href='https://web.facebook.com/harusionlinetz' target='_blank' aria-label='Facebook'>
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href='https://www.instagram.com/harusionline/' target='_blank' aria-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink>
                {/* <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink> */}
                  </SocialHolder>
              </FooterBottom>   
          </FooterContainer>
                     
          
            
        </FooterWrapper>
    )
}

export default Footer
