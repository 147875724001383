import React,{useEffect, useState} from 'react';

import { AppLogo, Bars, Nav, NavMenu,NavLink } from './Navbar.elemets';
import logo from '../../assets/images/app_logo.png';
import SearchInputField from '../Textfield/SearchInput';
import { useHistory, useLocation } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa'



function Navbar() {
  const [click, setClick] = useState(false);
  const [searchTerm, setsearchTerm] = useState("")

  const handleClick = () => {

    setClick(!click)
  };
  const [ishome,setishome] = useState(false);
  const location = useLocation();

  
  const history = useHistory();



  useEffect(()=>{
    location.pathname === '/' ? setishome(true) : setishome(false);
  },[location.pathname]);

  const routeChange = () =>{ 
    
    // history.push(path);
    console.log("current term ",searchTerm)
    history.push({

      pathname: `/global_search/:${searchTerm}`
      // search: `:${termOnchange}`

  });
  }
    return (
        <Nav>
            <NavLink to="/" onClick={()=>setishome(true)}>
            <AppLogo src={logo} />
            </NavLink>
            <Bars onClick={handleClick} />
            
            <NavMenu click={click}>
            {ishome &&  <SearchInputField displayType='all' clickListener = {()=>{
             routeChange();
              setishome(false);
            }} onChange={(val:any)=>{
              // setSearchTerm(val);
              setClick(!click)
              setsearchTerm(val)
              
            }}/>}
            {/* <NavLink to='/' onClick={()=>setishome(true)}>
            Home
          </NavLink> */}
            {/* <NavLink to='/about' >
            About
          </NavLink> */}
          <NavLink to='/watoa-huduma' >
          Watoa Huduma
          </NavLink>
          <NavLink to='/pricing' >
            Gharama
          </NavLink>
          <NavLink to='/login'  onClick={()=>{setClick(!click)}}>
          Ingia/Jisajili
          </NavLink>
          <NavLink to=''  >
            <FaWhatsapp /> <span> 0747-636-516 </span>
          </NavLink>
            </NavMenu>
            
        </Nav>
    )
}

export default Navbar
