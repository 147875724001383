// import './App.css';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Footer, Navbar} from './components'
import { Loader } from './components/Loader/Loader';
// import AllEvents from './pages/AllEvents/AllEvents';
// import AllServiceProviders from './pages/AllServiceProvider/AllServiceProviders';
import index from './pages/EventView/contoller';
// import GlobalSearch from './pages/GlobalSearch/GlobalSearch';
import Home from './pages/HomePage/Home';
// import ServiceProvider from './pages/ServiceProvider/ServiceProvider';

import GlobalStyle, { Container } from './theme/globalStyles';


const LoginPage = lazy(() => import('./pages/UserLogin/Login'));
const TermsPage = lazy(()=>import('./pages/Terms/terms'));
const CheckEmail = lazy(()=>import('./pages/CheckEmail/index'));
const PasswordReset = lazy(() => import('./pages/PasswordReset/Reset'));
const DeleteAccount = lazy(() => import('./pages/DeleteAccount/index'));
const Dashboard = lazy(()=>import('./pages/Dashboard/Home'))
const ServiceProvider = lazy(()=>import('./pages/ServiceProvider/ServiceProvider'))
const AllEvents = lazy(()=>import('./pages/AllEvents/AllEvents'))
const AllServiceProviders = lazy(()=>import('./pages/AllServiceProvider/AllServiceProviders'))
const GlobalSearch = lazy(()=>import('./pages/GlobalSearch/GlobalSearch'))
const Reset = lazy(()=>import('./pages/NewPassword/index'))
const CommitteeAccountActivate = lazy(()=>import('./pages/NewPassword/Committee'))
const YouLost = lazy(()=>import('./pages/404/index'))
const UserRegister = lazy(()=>import('./pages/UserRegistration/UserRegistration'))
const MobileCards = lazy(() => import('./pages/MobileCards/index'))
const AllCardTemplates = lazy(() => import('./pages/CardTemplates/CardTemplates'))
const Pricing = lazy(() => import('./pages/Commons/Pricing'))


function App() {
  if (process.env.NODE_ENV === 'production') {
    //removing all logs while in production. 
    console.log = () => {} 
  }
  console.log('Host ',window.location.host);
  if(window.location.host === "www.harusi.online" || window.location.host === "harusionline.co.tz" || window.location.host === "harusi.online"  || window.location.host.split(".")[0] === "test1" || window.location.host.split(".")[0] === "harusi-online-fe" || window.location.host.split(".")[0] === "localhost:3000"|| window.location.host.split(".")[0] === "localhost:3001" || window.location.host.split(".")[0] === "192.168.43.218:3000" || window.location.host.split(".")[0] === "staging"){
    //render landing page
    
    return  (
      <>
      <GlobalStyle />
      <Suspense fallback={<Loader size="large" page={true}/>}>

      <Router>
     
        <Container>
       {
         window.location.pathname.includes('/dashboard') || window.location.pathname.includes('/ecardsmobile') ?  null : <Navbar/>
         
       }
  
        <Switch>
        <Route path="/terms" component={TermsPage}/>
        <Route  path="/dashboard" component={Dashboard}/>
        <Route exact path="/" component={Home}/>
        <Route  path={["/service_provider/:id","/sp/:id"]} component={ServiceProvider}/>
        <Route path="/reset/:token" component={Reset}/>
        <Route path="/committee/:token" component={CommitteeAccountActivate}/>
        <Route exact path="/all-events" component={AllEvents}/>
        <Route path={["/all-sp","/watoa-huduma"]} component={AllServiceProviders}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/forgot-password" component={PasswordReset}/>
        <Route path="/delete-account" component={DeleteAccount} />
        <Route path="/check-email" component={CheckEmail}/>
        <Route path="/ecardsmobile/:uid/:token" component={MobileCards}/>
        <Route path={["/register/:action","/sp_register"]} component={UserRegister}/>
        <Route exact path="/global_search/:term" component={GlobalSearch} />
        <Route exact path="/kadi-za-kidigitali/templates" component={AllCardTemplates} />
        <Route exact path="/pricing" component={Pricing} />
        <Route>
          <YouLost/>
        </Route>
        </Switch>
        </Container>
        
       {
           window.location.pathname !== '/login' && window.location.pathname !== '/dashboard'   ? <Footer/> : null
       }
       
      </Router>
      </Suspense>
    </>
    );
  }else{
    //render event view
    return  (
      <Router>
        <GlobalStyle />
        {/* <Container>
        <Navbar/> */}
        <Switch>
        <Route exact path={["/","/index.html"]} component={index} />
        {/* <Route path="/event" component={EventView} /> */}
        </Switch>
        {/* </Container>
        <Footer/> */}
      </Router>
     
    );
  }
}

export default App;
