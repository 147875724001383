import React, { useEffect, useRef, useState } from 'react'
import { ApiClient } from '../../Data/ApiClient';
import { CardTemplate } from '../../Data/Classes/CardTempate';
import { Event } from '../../Data/Classes/Event';
import { ServiceProvider } from '../../Data/Classes/Service_provider';
import Card from '../Card/Card';
import { CardWrapper } from '../Card/Card.elements';
import GlobalProgressLoader from '../Common/GlobalLoader';
import { AiOutlineLeftIcon, AiOutlineRightIcon, BodyLeftRightIconsHolder, DivHolder, FeaturedServiceProvidersWrapper, FeaturesHolder, ProgressHolder, SectionHeader, SectionHeaderEvents, SectionHeaderSps, ServiceProviderViewAll, UpcomingEvents } from './Body.elements'
import {data, data2, data3} from './Data';
import Feature from './Feature';
import { useHistory } from 'react-router-dom';
import { CTABTN } from '../../theme/globalStyles';



const Body:React.FC = ()=> {
    // let { path, url } = useRouteMatch();
    const history = useHistory();
    const [cardTemplates,setCardTemplates] = useState<CardTemplate[]>([]);
    const [featuredServiceProvider,setFeaturedServiceProviders] = useState<ServiceProvider[]>([]);
    const [showLoader,setShowLoader] = useState(false);
    const [showSpLoader,setShowSpLoader] = useState(false);
    const spDivRef = useRef<any>(null);
    const eventDivRef = useRef<any>(null);
    const getCardTemplates = ():void =>{
        setShowLoader(true);
        ApiClient.get('ecards/templates').then((response) => {
            let templates = response.data.data;
            let cardTemplates: CardTemplate[] = templates.map((cardtemplate:any) => new CardTemplate(cardtemplate));
            setCardTemplates(cardTemplates);
            setShowLoader(false);
        }).catch((error)=>{
            console.log('error',error)
        })
    }

    const getFeaturedSp = ():void =>{
        setShowSpLoader(true);
        ApiClient.get('service_providers').then((response)=>{
            let AllSpObject = response.data;
            // Location[] = allLocationsArray.map((loc:any) => new Location(loc));
            let serviceProviderArray:ServiceProvider[] = AllSpObject.map((sp:any) => new ServiceProvider(sp));
            // console.log('success >>> ',serviceProviderArray)
            // setUpcomingEvents(PassedEventsArray);
            setFeaturedServiceProviders(serviceProviderArray);
            setShowSpLoader(false);
        }).catch((error)=>{
            console.log('error',error)
        })
    }


    const spScroll = (scrollOffset:number) => {
        if(spDivRef.current !== null){
        spDivRef.current.scrollLeft += scrollOffset;
        }
      };



      const eventScroll = (scrollOffset:number) => {
        if(eventDivRef.current !== null){
        eventDivRef.current.scrollLeft += scrollOffset;
        }
      };


    useEffect(() => {
        const fetchData = async () => {
          getCardTemplates()
          getFeaturedSp()
          }

          fetchData()
          }
    , []);
    return (
        <DivHolder>                 

           <UpcomingEvents>
           <SectionHeaderEvents>
                Kadi za Kidigitali - Templates
            <BodyLeftRightIconsHolder>
               <AiOutlineLeftIcon onClick={()=>eventScroll(-20)}/>
               <AiOutlineRightIcon onClick={()=>eventScroll(20)}/>
           </BodyLeftRightIconsHolder>
           </SectionHeaderEvents>
           <ProgressHolder>
           <GlobalProgressLoader isVisible={showLoader} width={50} height={50}/>
           </ProgressHolder>
           <CardWrapper ref={eventDivRef}>
            {cardTemplates.length !== 0 && (           
                        cardTemplates.map((ecard:CardTemplate,index)=>{                    
                   return(
                       <Card index={index} key={index} url={ecard.template_id} title={ecard.name} date={ ecard.price + "/= " + ecard.card_category } wallpaper_url={ecard.preview_path } />
                   );
                   
                })

                
            )}
         
                </CardWrapper>
                <CTABTN onClick={() => {
                    history.push('kadi-za-kidigitali/templates')
                }}>
                    Templates Zaidi..
                </CTABTN>
           </UpcomingEvents>
           
           <FeaturedServiceProvidersWrapper>
           <SectionHeaderSps>
               Watoa Huduma
               <BodyLeftRightIconsHolder>
               <AiOutlineLeftIcon onClick={()=>spScroll(-20)}/>
               <AiOutlineRightIcon onClick={()=>spScroll(20)}/>
           </BodyLeftRightIconsHolder>
           </SectionHeaderSps>
           <ProgressHolder>
           <GlobalProgressLoader isVisible={showSpLoader} width={50} height={50}/>
           </ProgressHolder>
      
           <CardWrapper ref={spDivRef}>
                {/* {console.log("should render >> ",featuredServiceProvider[0])} */}
               {featuredServiceProvider.length !== 0 && (
                   featuredServiceProvider.map((sp:ServiceProvider,index)=>{

                   return(
                    index < 10 && (
                       
                    
                        <Card index={index} key={index}  url={sp.id} title={sp.name} date={sp.location} wallpaper_url={sp.wallpaper != null ? sp.wallpaper.medium : undefined} isSp={true}
                        spCategory = {sp.categories} 
                    />
                   
                    )
                   )
                       
                        
                   })
               )}
       
           
                </CardWrapper>
                <CTABTN onClick={() => {
                    history.push('all-sp')
                }}>
                    Tazama Wote..
                </CTABTN>           
           </FeaturedServiceProvidersWrapper>


          

        </DivHolder>
    )


    
}

export default Body

