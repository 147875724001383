import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {  PledgeForm, RsvpModal } from '../../../components';
import { Event } from '../../../Data/Classes/Event';
import { Photo } from '../../../Data/Classes/Photo';

import { AdBody, AdHeader, BasicFooter, ContactSection, EventAdminContacts, EventAdminLink, EventCaption, EventDate, EventDetailsHolder, EventHeader, EventTitle, FirstSection, FirstSectionData, GallerUL, GalleryImgHolder, GalleryLi, GooglePlayIcon, HarusiOnlineAd, ImageHolder, LocationDetails, MapHead, MapHolder, RSVPbtn, ScrollableSectionHolder, SecondSection, Section, SectionHeading, ThirdSection, TopSection } from '../Event.elements'

type themeData = {
    event_date:any,
    eventObject?:Event,
    showModal:boolean
}

const EventView:React.FC<themeData>=({eventObject,event_date,showModal}) => {
//   function formatDate(date:any){
//     console.log("this is formatted",date ,"to ",new Date(date.replace(/-/g, "/")).toDateString())
//     return new Date(date.replace(/-/g, "/")).toDateString()
// }  
//   // event_date = formatDate(event_date);

//   console.log("plain date ",eventObject?.event_date)

  const calculateTimeleft =() =>{
    const difference = +new Date(eventObject !== undefined ? eventObject.event_date.replace(/-/g, "/"):new Date()) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        // seconds: Math.floor((difference / 1000) % 60),
      };
    
    }
    return timeLeft;
  }


    
  const [isRsvpModalOpen, setRsvpModalOpen] = useState(true);

  // const [timeLeft, setTimeLeft] = useState<any>(calculateTimeleft());
  const timeLeft:any = calculateTimeleft();

  
      

    function toggleModal() {
        setRsvpModalOpen(!isRsvpModalOpen);
      }


      useEffect(() => {
        setRsvpModalOpen(showModal)
      }, [showModal])


    // useEffect(() => {
     
    //     // setTimeout(() => {
    //       setTimeLeft(calculateTimeleft());
    //     // }, 1000);
    //   }, [eventObject]);

    // if(timeLeft.days !== undefined){
    //   setRsvpModalOpen(true);
    // }

    
      const timerComponents:any = [];
    if(timeLeft !== null || timeLeft !== undefined){

      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
    
        timerComponents.push(
          <span>
            {timeLeft[interval]} {interval}{" "}
          </span>
        );
      });
    }
    
    return(
      <>
      <Helmet>
            <title>
              {eventObject?.event_title !== undefined ? eventObject?.event_title : "Harusi Online Event"} | {event_date}
              </title>
            <meta name="description" content={eventObject?.event_message !== undefined ? eventObject.event_message : "Its gonna be a party of the year"} />
        </Helmet>
        <Section>
     
        <TopSection>
            <ImageHolder src = {eventObject?.wallpaper !== undefined && eventObject?.wallpaper?.length  > 0 ? eventObject?.wallpaper[0].large :undefined}>
                <EventDetailsHolder>
                    <EventHeader>{eventObject?.event_title}</EventHeader>

                    <EventCaption>{eventObject?.event_message !== undefined ? eventObject.event_message : "Its gonna be a party of the year"}</EventCaption>
                </EventDetailsHolder>
            </ImageHolder>
            <ScrollableSectionHolder>
                <FirstSection>
                    <FirstSectionData>
                        <EventTitle>
                            {eventObject !== undefined ? eventObject.event_title : "Sample Data"}
                        </EventTitle>
                        <EventDate>{event_date}</EventDate>
                       
                        <EventDate>{timerComponents.length ? timerComponents : timeLeft.days === 1 ? <span>Its Today.!</span> : ""}</EventDate>
.
                        {timeLeft.days > 1 ? <RSVPbtn onClick={toggleModal}>Shiriki/Changia</RSVPbtn> : undefined}
                    </FirstSectionData>
                </FirstSection>
                <SecondSection>
                    <SectionHeading>
                            Schedule

                           
                    </SectionHeading>
                          <MapHead>Reception</MapHead>
                            <MapHolder>
                            <LocationDetails>
                           {eventObject !== undefined ? eventObject.event_location_reception:"Arusha"}<br/>
                            {event_date}
                            </LocationDetails>
                            </MapHolder>

                            
                            <MapHead>Ibada</MapHead>
                            <MapHolder>
                            <LocationDetails>
                            {eventObject !== undefined ? eventObject.event_location_ibada:"arusha"}<br/>
                            {eventObject !== undefined ? eventObject.event_time_reception:"arusha"}
                            </LocationDetails>
                            </MapHolder>

                          
                </SecondSection>

                <ThirdSection>
                    <SectionHeading>
                        Gallery
                    </SectionHeading>

                    <GallerUL>
                        {eventObject !== undefined && eventObject.photos?.map((image:Photo,index:number)=>{
                          return(
                            <GalleryLi key={index}>
                            <GalleryImgHolder src={image.medium}/>
                        </GalleryLi>
                          );
                        })}
                    </GallerUL>
                </ThirdSection>

                <ContactSection>
                    <SectionHeading>
                        Contacts
                    </SectionHeading>
                    <EventAdminContacts>
                    {eventObject !== undefined ? eventObject.mobile : "00"}<br/>
                   {eventObject !== undefined ?  eventObject.email : "00"}

                    <br></br>

                    
                    </EventAdminContacts>
                    <EventAdminLink href={"https://www.harusi.online/login"}>
                        Event Admin
                    </EventAdminLink>

                </ContactSection>


                <HarusiOnlineAd>
                    <AdHeader>We have an App</AdHeader>
          
                    <AdBody>Download HarusiOnline and join our app using the event password we sent you. View and share photos, see who else is coming, get up-to-date details, and follow along.</AdBody>

                    <GooglePlayIcon href="https://play.google.com/store/apps/details?id=harusi.online.mobile"/>

                    <BasicFooter>Made With HarusiOnline</BasicFooter>
                </HarusiOnlineAd>
            </ScrollableSectionHolder>
            <RsvpModal eventLocation={eventObject !== undefined ? eventObject.event_location_reception : ""} eventName={eventObject !== undefined ?  eventObject.event_title:""} eventDate={event_date} showModal={isRsvpModalOpen}  setShowModal={toggleModal} modalBody = { <PledgeForm />}/>
        </TopSection>

        
        </Section>
        </>
    );
};


export default EventView;